import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Scheduler from "./scheduler/Scheduler";
import FrontPage from "./front-page/FrontPage";
import theme from "./config/theme";
import "./App.css";
import SignInScreen from "./common/SignInScreen";
import { firebaseConfig } from "./config/firebase";
import { initializeApp } from "firebase/app";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Services from "./services/Services";
import PageContainer from "./common/PageContainer";
import Confirmation from "./confirmation/Confirmation";
import SessionPackPurchase from "./session-pack/SessionPackPurchase";
import PackConfirmation from "./confirmation/PackConfirmation";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import AdminPage from "./admin/AdminPage";

const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcIHWAoAAAAAGE9YezbGD6IkcBLAL-_v2rtXdm_"),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export default function App({ children }: any) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <PageContainer>
          <FrontPage />
        </PageContainer>
      ),
    },
    {
      path: "/login",
      element: (
        <PageContainer>
          <SignInScreen />
        </PageContainer>
      ),
    },
    {
      path: "/services",
      element: (
        <PageContainer>
          <Services />
        </PageContainer>
      ),
    },
    {
      path: "/confirmation/:bookingId",
      element: (
        <PageContainer>
          <Confirmation />
        </PageContainer>
      ),
    },
    {
      path: "/confirmation/packs/:packPurchaseId/:userId",
      element: (
        <PageContainer>
          <PackConfirmation />
        </PageContainer>
      ),
    },
    {
      path: "/scheduler/private-session",
      element: (
        <PageContainer>
          <Scheduler serviceName="Private Session" />
        </PageContainer>
      ),
    },
    {
      path: "/scheduler/drop-in-class",
      element: (
        <PageContainer>
          <Scheduler serviceName="Drop-In Class" />
        </PageContainer>
      ),
    },
    {
      path: "/scheduler/duet-session",
      element: (
        <PageContainer>
          <Scheduler serviceName="Duet Session" />
        </PageContainer>
      ),
    },
    {
      path: "/packs/:service/:units",
      element: (
        <PageContainer>
          <SessionPackPurchase />
        </PageContainer>
      ),
    },
    {
      path: "/admin",

      element: (
        <PageContainer>
          <AdminPage />
        </PageContainer>
      ),
    },
  ]);

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </div>
  );
}
