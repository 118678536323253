import { OpenInBrowser } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocFromServer,
  getDocs,
  getFirestore,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  isPhoneNumber,
  isUserAdmin,
  pathFromServiceName,
  prices,
  removeBooking,
  sendConfirmationEmail,
  timeBlockStringFromStartTime,
  timeSlotsOnDate,
} from "../util";
import { stripe } from "../config/stripe";
import { Dayjs } from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";

export default function TimeBlockPicker({
  selectedDate,
  serviceName,
  slots,
}: {
  selectedDate: Date;
  serviceName: string;
  slots: any[];
}) {
  const [selectedSlot, setSelectedSlot] = useState<Date | null>(null);
  const [allSlots, setAllSlots] = useState<any[]>([]);
  const [filteredSlots, setFilteredSlots] = useState<any[]>([]);
  const [slotRecord, setSlotRecord] = useState<DocumentData | null>(null);
  const [slotBookings, setSlotBookings] = useState<DocumentData[]>([]);
  const [slotCustomers, setSlotCustomers] = useState<DocumentData[]>([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [adminCreditDialogOpen, setAdminCreditDialogOpen] = useState(false);
  const [alreadyBookedDialogOpen, setAlreadyBookedDialogOpen] = useState(false);
  const [newBookingDialogOpen, setNewBookingDialogOpen] = useState(false);
  const [customerDoc, setCustomerDoc] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [customerName, setCustomerName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [userFullName, setUserFullName] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [customerIsSlidingScale, setCustomerIsSlidingScale] = useState<boolean>(
    serviceName === "Drop-In Class"
  );
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [paymentLinkDialogOpen, setPaymentLinkDialogOpen] = useState(false);
  const [bookingToRemove, setBookingToRemove] = useState<DocumentData | null>(
    null
  );
  const [customerToRemove, setCustomerToRemove] = useState<DocumentData | null>(
    null
  );
  const [removalConfirmationOpen, setRemovalConfirmationOpen] = useState(false);
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<DocumentData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const closeConfirmation = () => setConfirmationOpen(false);

  const isSlotFull = (slotTime: Date) => {
    if (!slotTime) return false;
    const slotDoc = filteredSlots.find(
      (slot) =>
        slot.service === serviceName &&
        (slot.startTime as Dayjs).unix() * 1000 === slotTime?.getTime()
    );
    if (!slotDoc) {
      console.error("Slot not found.");
      return true;
    }
    return slotDoc.bookingIds.length >= slotDoc.maxBookings;
  };

  const handleSearch = async (name: string) => {
    setIsLoading(true);
    const db = getFirestore();
    const searchQuery = query(
      collection(db, "customers"),
      where("name", ">=", name),
      where("name", "<=", name + "\uf8ff")
    );
    const snapshot = await getDocs(searchQuery);
    if (snapshot.empty) {
      setIsLoading(false);
      return;
    }
    const results: Set<DocumentData> = new Set();
    snapshot.docs.forEach((doc) => results.add({ id: doc.id, ...doc.data() }));
    setSearchResults(Array.from(results));
    setIsLoading(false);
  };

  const loadSlotRecords = async (slot: Date) => {
    setSlotBookings([]);
    setSlotCustomers([]);
    const db = getFirestore();
    const minTimestamp = new Date(slot);
    const maxTimestamp = new Date(slot);
    minTimestamp.setMilliseconds(0);
    minTimestamp.setSeconds(0);
    maxTimestamp.setMilliseconds(999);
    maxTimestamp.setSeconds(59);
    const slotRef = collection(db, "slots");
    const slotsQuery = query(
      slotRef,
      where("startTime", ">=", minTimestamp),
      where("startTime", "<=", maxTimestamp)
    );
    const slotDocs = await getDocs(slotsQuery);
    const slotDoc = slotDocs.docs.find(
      (doc) => doc.data().service === serviceName
    );
    if (!slotDoc) {
      console.error("Slot not found.");
      return;
    }
    setSlotRecord({ id: slotDoc.id, ...slotDoc.data() });
    if (!slotDoc.data()?.bookingIds.length) {
      return;
    }
    const bookingsRef = slotDoc
      .data()
      .bookingIds.map((bookingId: string) => doc(db, "bookings", bookingId));
    const bookingsDocs = await Promise.all(
      bookingsRef.map(async (bookingRef: any) => await getDoc(bookingRef))
    );
    const bookings = bookingsDocs.map((doc) => {
      return { id: doc.id, ...(doc.data() as DocumentData) } as DocumentData;
    });
    setSlotBookings(bookings);
    if (isAdmin) {
      const customerIds: string[] = bookings
        .map((booking) => booking.customerId)
        .filter((id) => !!id && id.length > 0);

      if (!customerIds || !customerIds.length) {
        return;
      }
      const customersRef = customerIds.map((customerId: string) =>
        doc(db, "customers", customerId)
      );
      const customersDocs = await Promise.all(
        customersRef.map(async (customerRef: any) => await getDoc(customerRef))
      );
      const customers = customersDocs.map((doc) => {
        return { id: doc.id, ...(doc.data() as DocumentData) } as DocumentData;
      });
      setSlotCustomers(customers);
    }
  };

  const isUserAlreadyBooked = (customerId: string | null) => {
    if (!customerId) {
      return false;
    }
    slotBookings.forEach((booking) => {
      if (booking.customerId === customerId) {
        return true;
      }
    });
  };

  const handleManualBooking = async () => {
    if (!selectedSlot) {
      console.error("No slot selected.");
      return;
    }
    // Try to find existing user by email
    const db = getFirestore();
    const customersRef = collection(db, "customers");
    const customersQuery = query(
      customersRef,
      where("email", "==", customerEmail)
    );
    const customersDocs = await getDocs(customersQuery);
    let customerDoc = null;
    let customerId: string | null = null;
    if (!customersDocs.empty) {
      customerId = customersDocs.docs[0].id;
      customerDoc = customersDocs.docs[0];
    }
    if (customerDoc) {
      // Update customer name if it doesn't exist
      if (!customerDoc.data().name) {
        await updateDoc(customerDoc.ref, { name: customerName });
      }
    }
    if (isUserAlreadyBooked(customerId)) {
      setAlreadyBookedDialogOpen(true);
      return;
    }
    // Try to use a credit for this booking
    const bookingsRef = collection(db, "bookings");
    let newBookingRef: DocumentReference<DocumentData> | null = null;
    let adminPaidWithCredit = false;
    if (
      customerDoc &&
      serviceName === "Private Session" &&
      customerDoc?.data()?.sessionCredits > 0
    ) {
      // Remove a credit
      await updateDoc(customerDoc.ref, {
        sessionCredits: customerDoc.data()?.sessionCredits - 1,
      });
      // Create a new booking to hold the time block
      newBookingRef = await addDoc(bookingsRef, {
        customerId: customerId,
        customerName: customerName,
        customerEmail: customerEmail,
        service: serviceName,
        createdAt: new Date(),
        startTime: selectedSlot,
        paidWithCredit: true,
        confirmed: true,
        admin: true,
      });
      await sendConfirmationEmail(newBookingRef.id);
      adminPaidWithCredit = true;
    } else if (
      customerDoc &&
      serviceName === "Duet Session" &&
      customerDoc?.data()?.duetCredits > 0
    ) {
      // Remove a credit
      await updateDoc(customerDoc.ref, {
        duetCredits: customerDoc.data()?.duetCredits - 1,
      });
      // Create a new booking to hold the time block
      newBookingRef = await addDoc(bookingsRef, {
        customerId: customerId,
        customerName: customerName,
        customerEmail: customerEmail,
        service: serviceName,
        createdAt: new Date(),
        startTime: selectedSlot,
        paidWithCredit: true,
        confirmed: true,
        admin: true,
      });
      await sendConfirmationEmail(newBookingRef.id);
      adminPaidWithCredit = true;
    } else {
      newBookingRef = await addDoc(bookingsRef, {
        customerId: customerId,
        customerName: customerName,
        customerEmail: customerEmail,
        service: serviceName,
        createdAt: new Date(),
        startTime: selectedSlot,
        confirmed: false,
        admin: true,
      });
    }
    // Add the booking to the slot
    const minTimestamp = new Date(selectedSlot);
    const maxTimestamp = new Date(selectedSlot);
    minTimestamp.setMilliseconds(0);
    minTimestamp.setSeconds(0);
    maxTimestamp.setMilliseconds(999);
    maxTimestamp.setSeconds(59);
    const slotRef = collection(db, "slots");
    const slotsQuery = query(
      slotRef,
      where("startTime", ">=", minTimestamp),
      where("startTime", "<=", maxTimestamp)
    );
    const slotDocs = await getDocs(slotsQuery);
    const slotDoc = slotDocs.docs.find(
      (doc) => doc.data().service === serviceName
    );
    if (!slotDoc) {
      console.error("Slot not found.");
      return;
    }
    if (slotDoc.data().bookingIds.length >= slotDoc.data().maxBookings) {
      console.error("Slot is full");
      return;
    }
    await updateDoc(slotDoc.ref, {
      bookingIds: [...slotDoc.data().bookingIds, newBookingRef.id],
    });
    // If paid with credit we don't need to create a payment link
    if (adminPaidWithCredit) {
      setPaymentLinkLoading(false);
      setNewBookingDialogOpen(false);
      setAdminCreditDialogOpen(true);
      setAdminDialogOpen(false);
      return;
    }
    // Get the different prices for this service
    const servicesRef = collection(db, "services");
    const servicesQuery = query(
      servicesRef,
      limit(1),
      where("name", "==", serviceName)
    );
    const servicesDocs = await getDocs(servicesQuery);
    if (servicesDocs.empty) {
      console.error("Service not found.");
      return;
    }
    const price = customerIsSlidingScale
      ? prices.find((p) => p.slidingScale && p.name === serviceName)
      : prices.find((p) => !p.slidingScale && p.name === serviceName);
    if (!price) {
      console.error("No price found.");
      return;
    }
    const priceId: string = price.priceId;
    // Create a new payment link
    const paymentLink = await stripe.paymentLinks.create({
      line_items: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      metadata: {
        bookingId: newBookingRef.id,
      },
      submit_type: "book",
      after_completion: {
        type: "redirect",
        redirect: {
          url: `https://daisyhodgepilates.com/confirmation/${newBookingRef.id}`,
        },
      },
    });
    if (!paymentLink || !paymentLink.url) {
      console.error("Failed to create payment link.");
      return;
    }
    setPaymentLink(paymentLink.url);
    setPaymentLinkLoading(false);
    setPaymentLinkDialogOpen(true);
  };

  const handleBooking = async () => {
    if (
      !userFullName.length ||
      !userPhone.length ||
      !isPhoneNumber(userPhone)
    ) {
      return;
    }
    if (!selectedSlot) {
      console.error("No slot selected.");
      return;
    }
    setPaymentLinkLoading(true);
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      console.error("User not logged in.");
      return;
    }
    if (isUserAlreadyBooked(currentUser.uid ?? null)) {
      setPaymentLinkLoading(false);
      setConfirmationOpen(false);
      setAlreadyBookedDialogOpen(true);
      return;
    }
    const db = getFirestore();
    // Create a new booking to hold the time block
    const bookingsRef = collection(db, "bookings");
    const newBookingRef = await addDoc(bookingsRef, {
      customerId: currentUser.uid,
      customerName: userFullName,
      customerEmail: currentUser.email,
      service: serviceName,
      createdAt: new Date(),
      startTime: selectedSlot,
      confirmed: false,
      admin: false,
    });
    // Add the booking to the slot
    const minTimestamp = new Date(selectedSlot);
    const maxTimestamp = new Date(selectedSlot);
    minTimestamp.setMilliseconds(0);
    minTimestamp.setSeconds(0);
    maxTimestamp.setMilliseconds(999);
    maxTimestamp.setSeconds(59);
    const slotRef = collection(db, "slots");
    const slotsQuery = query(
      slotRef,
      where("startTime", ">=", minTimestamp),
      where("startTime", "<=", maxTimestamp)
    );
    const slotDocs = await getDocs(slotsQuery);
    const slotDoc = slotDocs.docs.find(
      (doc) => doc.data().service === serviceName
    );
    if (!slotDoc) {
      console.error("Slot not found.");
      return;
    }
    if (slotDoc.data().bookingIds.length >= slotDoc.data().maxBookings) {
      console.error("Slot is full");
      return;
    }
    await updateDoc(slotDoc.ref, {
      bookingIds: [...slotDoc.data().bookingIds, newBookingRef.id],
    });
    // Get the customer document to save user details and to see if they have credit to use (private session only)
    if (!customerDoc?.exists()) {
      console.error("Customer not found.");
      return;
    }
    if (!customerDoc.data().name) {
      await updateDoc(customerDoc.ref, { name: userFullName });
    }
    if (!customerDoc.data().phone) {
      await updateDoc(customerDoc.ref, { phone: userPhone });
    }
    if (
      serviceName === "Private Session" &&
      customerDoc.data()?.sessionCredits > 0
    ) {
      // Remove a credit
      await updateDoc(customerDoc.ref, {
        sessionCredits: customerDoc.data()?.sessionCredits - 1,
      });
      // Update the booking to mark that it was paid for with a credit
      await updateDoc(newBookingRef, { paidWithCredit: true });
      // Redirect to confirmation page
      window.location.href = `${window.location.origin}/confirmation/${newBookingRef.id}`;
      return;
    } else if (
      serviceName === "Duet Session" &&
      customerDoc.data()?.duetCredits > 0
    ) {
      // Remove a credit
      await updateDoc(customerDoc.ref, {
        duetCredits: customerDoc.data()?.duetCredits - 1,
      });
      // Update the booking to mark that it was paid for with a credit
      await updateDoc(newBookingRef, { paidWithCredit: true });
      // Redirect to confirmation page
      window.location.href = `${window.location.origin}/confirmation/${newBookingRef.id}`;
      return;
    }
    // Otherwise, create payment link and redirect user to it
    // See if customer is allowed to choose their own price
    const userCanChoosePrice: boolean =
      (customerDoc.data()?.slidingScale ?? false) ||
      serviceName === "Drop-In Class";
    // Get the different prices for this service
    const servicesRef = collection(db, "services");
    const servicesQuery = query(
      servicesRef,
      limit(1),
      where("name", "==", serviceName)
    );
    const servicesDocs = await getDocs(servicesQuery);
    if (servicesDocs.empty) {
      console.error("Service not found.");
      return;
    }
    const price = userCanChoosePrice
      ? prices.find((p) => p.name === serviceName && p.slidingScale)
      : prices.find((p) => p.name === serviceName && !p.slidingScale);
    if (!price) {
      console.error("No price found.");
      return;
    }
    const priceId: string = price.priceId;
    // Create a new checkout session to get a payment URL
    const checkoutSessionRef = collection(
      db,
      "customers",
      currentUser.uid,
      "checkout_sessions"
    );
    const newCheckoutRef = await addDoc(checkoutSessionRef, {
      mode: "payment",
      price: priceId,
      success_url: `https://daisyhodgepilates.com/confirmation/${newBookingRef.id}`,
      cancel_url: `https://daisyhodgepilates.com/scheduler/${pathFromServiceName(
        serviceName
      )}`,
      metadata: {
        bookingId: newBookingRef.id,
      },
    });
    // Wait for a second to allow the cloud function to run, then poll the server for the payment URL
    await new Promise((r) => setTimeout(r, 1000));
    let paymentUrl;
    let polls = 0;
    do {
      // Increase the pollTime by 500ms for each poll after the first 3
      const pollTime = 500 + (polls > 3 ? (polls - 3) * 500 : 0);
      await new Promise((r) => setTimeout(r, pollTime));
      paymentUrl = (await getDocFromServer(newCheckoutRef)).data()?.url;
      if (paymentUrl) {
        break;
      }
    } while (!paymentUrl && polls++ < 10);
    if (polls === 10) {
      console.error("Failed to get payment URL from server.");
      return;
    }
    // Redirect to the payment URL
    window.location.href = paymentUrl;
  };

  useEffect(() => {
    const getAllSlots = async () => {
      setAllSlots(await timeSlotsOnDate(selectedDate, serviceName, isAdmin));
    };
    getAllSlots();
  }, [selectedDate, serviceName, isAdmin]);

  useEffect(() => {
    const getCustomerDoc = async (customerId: string) => {
      const db = getFirestore();
      const customerRef = doc(db, "customers", customerId);
      return getDoc(customerRef);
    };

    getAuth().onAuthStateChanged(async (user) => {
      if (user) {
        setIsSignedIn(!!user);
        const customerDoc = await getCustomerDoc(user.uid);
        if (customerDoc.exists()) {
          setCustomerDoc(customerDoc);
          setUserFullName(customerDoc.data()?.name ?? "");
          setUserPhone(customerDoc.data()?.phone ?? "");
        }
        const admin = isUserAdmin(user);
        setIsAdmin(admin);
        if (admin) closeConfirmation();
      } else {
        setIsSignedIn(false);
        setIsAdmin(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      setFilteredSlots(slots);
    } else {
      setFilteredSlots(
        slots.filter((doc) => doc.bookingIds.length < doc.maxBookings)
      );
    }
  }, [isAdmin, slots]);

  function getSlotColor(startTime: Dayjs) {
    const slot = filteredSlots.find((slot) => startTime.isSame(slot.startTime));
    if (!slot) return "primary";
    if (!isAdmin) {
      return "primary";
    }
    if (isSlotFull(slot?.startTime.toDate())) {
      return "warning";
    }
    if (!slot?.active) {
      return "error";
    }
    return "primary";
  }

  return (
    <>
      <Grid
        container
        rowSpacing={3}
        spacing={6}
        justifyContent="center"
        alignItems="center"
      >
        {allSlots.map((startTime: any) => {
          const timeBlockString = timeBlockStringFromStartTime(
            startTime.toDate()
          );
          const start = timeBlockString.split("-")[0];
          const end = timeBlockString.split("-")[1];
          const slot = filteredSlots.find((slot) =>
            startTime.isSame(slot.startTime)
          );
          const remainingSlots =
            slot?.maxBookings && slot?.bookingIds?.length
              ? slot.maxBookings - slot.bookingIds.length
              : 0;
          return (
            <Grid
              item
              xs={6}
              sm={3}
              key={startTime.unix()}
              display="flex"
              justifyContent="center"
            >
              <Badge
                badgeContent={`${
                  slot?.bookingIds?.length ?? slot?.maxBookings
                }/${slot?.maxBookings}`}
                invisible={!slot || slot.maxBookings <= 1 || !slot.active}
                color={
                  remainingSlots <= 2 && remainingSlots !== 0
                    ? "warning"
                    : "info"
                }
              >
                <Button
                  style={{ flexGrow: 1, minWidth: "110px" }}
                  variant="contained"
                  disabled={
                    !isUserAdmin(getAuth().currentUser) &&
                    (!filteredSlots
                      .map((slot) => Math.floor(slot.startTime.unix() / 60))
                      .includes(startTime.unix() / 60) ||
                      isSlotFull(startTime.toDate()))
                  }
                  color={getSlotColor(startTime)}
                  onClick={async () => {
                    setSelectedSlot(startTime.toDate());
                    loadSlotRecords(startTime.toDate());
                    if (!isSignedIn) {
                      window.open("/login");
                    }
                    if (isAdmin) {
                      setAdminDialogOpen(true);
                    } else {
                      setConfirmationOpen(true);
                    }
                  }}
                >
                  {
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <b>{`${start} -`}</b>
                      <b>{end}</b>
                    </div>
                  }
                </Button>
              </Badge>
            </Grid>
          );
        })}
      </Grid>

      <Dialog
        open={confirmationOpen}
        onClose={closeConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {<b>{!isSignedIn ? "" : "Confirm Booking Selection"}</b>}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {!isSignedIn ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <b style={{ paddingBottom: "40px" }}>Awaiting sign in...</b>
                <CircularProgress />
              </div>
            </>
          ) : (
            <>
              <b>{serviceName}</b>
              <br />
              <br />
              <b>{`${selectedDate.toDateString()}`}</b>
              <br />
              <b>{`from ${
                selectedSlot
                  ? timeBlockStringFromStartTime(selectedSlot).replace(
                      "-",
                      "to"
                    )
                  : "N/A"
              }`}</b>
              <br />
              <br />
              <p style={{ fontSize: "14px" }}>
                Click the button below to confirm your choice.
                {serviceName !== "Drop-In Class"
                  ? "If you have credits available, your booking will confirm automatically and a credit will be deducted. Otherwise, "
                  : ""}
                {serviceName !== "Drop-In Class" ? "y" : "Y"}ou will be
                redirected to Stripe to complete your payment and confirm your
                booking. Your time block will be reserved and you will have 15
                minutes to complete your payment before your booking is
                released. After booking, contact Daisy to reschedule or cancel
                your booking.
                <b>
                  {serviceName === "Drop-In Class" &&
                  selectedSlot?.getDay() === 5
                    ? " If completing same day registration for Friday class, please attend virtually."
                    : ""}
                </b>
                <br />
                <b>
                  If you are within 24 hours of your booking, you will not be
                  able to reschedule or cancel (with exception for emergencies).
                </b>
              </p>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                value={userFullName}
                required
                disabled={customerDoc?.data().name !== undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserFullName(event.target.value);
                }}
                sx={{ marginY: "10px" }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={userPhone}
                required
                disabled={customerDoc?.data().phone !== undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserPhone(event.target.value);
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          {!isSignedIn ? (
            <></>
          ) : (
            <LoadingButton
              loading={paymentLinkLoading}
              loadingPosition="end"
              variant="contained"
              onClick={handleBooking}
              endIcon={<OpenInBrowser />}
            >
              {!paymentLinkLoading ? "Book My Session" : "Redirecting"}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={alreadyBookedDialogOpen}
        onClose={() => setAlreadyBookedDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {<b>{!isSignedIn ? "" : "Confirm Booking Selection"}</b>}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Booking already exists for this time slot for the signed in user.
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            onClick={() => setAlreadyBookedDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={adminCreditDialogOpen}
        onClose={() => setAdminCreditDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {<b>{!isSignedIn ? "" : "Admin Booking Confirmed"}</b>}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {`Specified customer was booked using a ${serviceName} credit.`}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            onClick={() => setAdminCreditDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={adminDialogOpen}
        onClose={() => setAdminDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <b>Admin Booking Management</b>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {slotBookings.map((booking, index) => {
            const customer = slotCustomers.find(
              (c) => c.id === booking.customerId
            );
            const customerName = customer?.name ?? booking?.customerName;
            const customerEmail = customer?.email ?? booking?.customerEmail;
            return (
              <div key={index}>
                <Button
                  variant="contained"
                  startIcon={booking.confirmed ? <CheckIcon /> : <></>}
                  endIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => {
                    setBookingToRemove(booking);
                    if (customer) {
                      setCustomerToRemove(customer);
                    }
                    setRemovalConfirmationOpen(true);
                  }}
                >
                  {customerName ?? customerEmail ?? booking.id}
                </Button>
              </div>
            );
          })}
          {slotRecord && slotRecord.maxBookings <= slotBookings.length ? (
            <></>
          ) : (
            <IconButton onClick={() => setNewBookingDialogOpen(true)}>
              <AddCircleIcon />
            </IconButton>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            disabled={!slotRecord}
            color={slotRecord?.active ? "error" : "success"}
            onClick={async () => {
              if (!slotRecord || !slotRecord.id) {
                console.error("Slot not found.");
                return;
              }
              const db = getFirestore();
              const slotRef = doc(db, "slots", slotRecord.id);
              await updateDoc(slotRef, { active: !slotRecord.active });
              setSlotRecord({ ...slotRecord, active: !slotRecord.active });
              filteredSlots.find((slot) => slot.id === slotRecord.id)!.active =
                !slotRecord.active;
            }}
          >
            {slotRecord?.active ? "Deactivate Slot" : "Activate Slot"}
          </Button>
          <Button variant="contained" onClick={() => setAdminDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={removalConfirmationOpen && !!customerToRemove}
        onClose={() => setRemovalConfirmationOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <b>Remove Booking?</b>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Are you sure you want to remove booking for{" "}
          {customerToRemove
            ? customerToRemove?.displayName ?? customerToRemove?.email
            : bookingToRemove?.customerName}
          ?
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            onClick={async () => {
              removeBooking(bookingToRemove!.id);
              setSlotBookings(
                slotBookings.filter((b) => b.id !== bookingToRemove!.id)
              );
              if (customerToRemove) {
                setSlotCustomers(
                  slotCustomers.filter((c) => c.id !== customerToRemove!.id)
                );
              }
              if (
                bookingToRemove?.paidWithCredit &&
                bookingToRemove.customerId
              ) {
                const db = getFirestore();
                const customerRef = doc(
                  db,
                  "customers",
                  bookingToRemove.customerId
                );
                await updateDoc(customerRef, {
                  sessionCredits: customerToRemove?.sessionCredits + 1,
                });
              }

              setRemovalConfirmationOpen(false);
              setCustomerToRemove(null);
            }}
          >
            Confirm Removal
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newBookingDialogOpen}
        onClose={() => setNewBookingDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <b>Manual Booking</b>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Autocomplete
            freeSolo
            options={searchResults}
            getOptionLabel={(option: any) => option.name || ""}
            onInputChange={(event: any, newValue: string) => {
              handleSearch(newValue);
            }}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setCustomerName(newValue.name);
                setCustomerEmail(newValue.email);
              } else {
                setCustomerName("");
                setCustomerEmail("");
              }
            }}
            loading={isLoading}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Customer Name"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <TextField
            label="Customer Email"
            variant="outlined"
            fullWidth
            value={customerEmail}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerEmail(event.target.value);
            }}
          />
          {serviceName === "Drop-In Class" ? (
            <></>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerIsSlidingScale}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCustomerIsSlidingScale(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Sliding Scale?"
            />
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <LoadingButton
            loading={paymentLinkLoading}
            loadingPosition="end"
            variant="contained"
            onClick={async () => {
              setPaymentLinkLoading(true);
              setPaymentLink(null);
              await handleManualBooking();
              setCustomerEmail("");
              setCustomerName("");
              setNewBookingDialogOpen(false);
            }}
            endIcon={<AddIcon />}
          >
            {!paymentLink ? "Add Booking" : "Loading Payment Link..."}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={paymentLinkDialogOpen && !adminCreditDialogOpen}
        onClose={() => {
          setPaymentLinkDialogOpen(false);
          setAdminDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <b>Payment Link</b>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {!!paymentLink ? (
            <>
              <TextField
                label="Payment Link"
                variant="outlined"
                fullWidth
                value={paymentLink}
                disabled
              />
              <br />
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  navigator.clipboard.writeText(paymentLink!);
                }}
              >
                Copy to Clipboard
              </Button>
            </>
          ) : adminCreditDialogOpen ? (
            <></>
          ) : (
            <Typography variant="body1">
              Payment link failed to load, please try again.
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setAdminDialogOpen(false);
              setPaymentLinkDialogOpen(false);
              setPaymentLinkLoading(false);
              setPaymentLink(null);
              setCustomerName("");
              setCustomerEmail("");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
