import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import pose from "../resources/pose.jpeg";
import plank from "../resources/plank.jpeg";
import headshot from "../resources/headshot.jpeg";
import outside from "../resources/outside.jpeg";
import teachingCadillac from "../resources/teachingCadillac.jpeg";
import teachingReformer from "../resources/teachingReformer.jpeg";
import movementClass from "../resources/movementClass.jpeg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  mobileStepper: {
    backgroundColor: "transparent",
    "& .MuiMobileStepper-dot": {
      backgroundColor: "#333",
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "#fff",
    },
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    bottom: 0,
    width: "100%",
  },
  backButton: {
    display: "none",
  },
  nextButton: {
    display: "none",
  },
};

const images = [
  {
    label: "headshot",
    src: headshot,
  },
  {
    label: "teaching pilates on the cadillac",
    src: teachingCadillac,
  },
  {
    label: "teaching pilates at movement studio",
    src: movementClass,
  },
  {
    label: "plank",
    src: plank,
  },
  {
    label: "teaching pilates on the reformer",
    src: teachingReformer,
  },
  {
    label: "dance pose",
    src: pose,
  },
  {
    label: "teaching pilates outside",
    src: outside,
  },
];

export default function PictureCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, position: "relative" }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={5000}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "100%",
                  height: 250,
                  objectFit: "contain",
                }}
                src={step.src}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="bottom"
        activeStep={activeStep}
        sx={styles.mobileStepper}
        nextButton={<div style={{ display: "none" }} />}
        backButton={<div style={{ display: "none" }} />}
      />
    </Box>
  );
}
