import Stripe from "stripe";

export const stripe =
    process.env.REACT_APP_ENV === 'production'
    ? new Stripe(
        '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',
        { apiVersion: '2023-08-16' }
    )
    : new Stripe(
        'sk_test_51NONTsLhPpLKakdEgFgtncNVtfm4zIk0vQb1kjKeoSOgDE2EFMd5zHP5U0hWk7my6wqjIBTzVsrFlwVhJ1OgoWdv00iwy8GBTf', 
        { apiVersion: '2023-08-16' }
    );