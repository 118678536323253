import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import {
  doc,
  getDoc,
  getDocFromServer,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { CheckCircleTwoTone } from "@mui/icons-material";
import { sendConfirmationEmail } from "../util";

const PackConfirmationPage: React.FC = () => {
  const { packPurchaseId, userId } = useParams();
  const [creditsAdded, setCreditsAdded] = useState<number>(0);
  const [serviceName, setServiceName] = useState<string>("");
  const [customer, setCustomer] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId || !packPurchaseId) {
      setError("No user ID or pack purchase ID provided");
      return;
    }
    const fetchPackagePurchase = async () => {
      const db = getFirestore();
      const packPurchaseRef = doc(
        db,
        "customers",
        userId,
        "pack_purchases",
        packPurchaseId
      );
      const packPurchaseDoc = await getDoc(packPurchaseRef);
      if (!packPurchaseDoc.exists()) {
        setError("Pack purhcase not found for given ID and user.");
      } else {
        const packPurchase = packPurchaseDoc.data();
        if (packPurchase.credited) {
          setError("Pack Purchase already credited.");
        } else {
          // Credit the pack purchase
          const customerRef = doc(db, "customers", userId);
          const customerDoc = await getDoc(customerRef);
          if (!customerDoc.exists()) {
            setError("Customer not found");
            return;
          }
          if (packPurchase.service === "Private Session") {
            await updateDoc(customerDoc.ref, {
              sessionCredits:
                (customerDoc.data().sessionCredits ?? 0) + packPurchase.units,
            });
          } else {
            await updateDoc(customerDoc.ref, {
              duetCredits:
                (customerDoc.data().duetCredits ?? 0) + packPurchase.units,
            });
          }
          setCustomer((await getDocFromServer(customerDoc.ref)).data());
          // Update the booking to confirmed
          await updateDoc(packPurchaseDoc.ref, { credited: true });
          setCreditsAdded(packPurchase.units);
          setServiceName(packPurchase.service);

          // Send email
          await sendConfirmationEmail(undefined, {
            packPurchaseId: packPurchaseId,
            userId: userId,
          });
        }
      }
    };
    fetchPackagePurchase();
  }, [packPurchaseId, userId]);

  if (error) {
    return (
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          style={{ padding: "2em", marginTop: "2em", textAlign: "center" }}
        >
          <Typography variant="h4" color="error">
            {error}
          </Typography>
          <Link to={"/"}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "2em" }}
            >
              Go Home
            </Button>
          </Link>
        </Paper>
      </Container>
    );
  }

  if (!creditsAdded && !error) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        style={{ padding: "2em", marginTop: "2em", textAlign: "center" }}
      >
        <Grid
          container
          justifyContent={"center"}
          paddingX="20px"
          paddingTop="40px"
        >
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <b>Your pack purchase of</b>
              <br />
              <br />
              {`${creditsAdded} ${serviceName}s`}
              <br />
              <br />
              <b>has been confirmed!</b>
              <br />
              <br />
              Customer now has{" "}
              <b>
                {serviceName === "Private Session"
                  ? customer.sessionCredits
                  : customer.duetCredits}
              </b>{" "}
              {serviceName}s credited.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            {creditsAdded && (
              <CheckCircleTwoTone color="success" style={{ fontSize: 60 }} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Link to={"/"}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "2em" }}
              >
                Go Home
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PackConfirmationPage;
