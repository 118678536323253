import dayjs, { Dayjs } from "dayjs";
import { User } from "firebase/auth";
import {
  getFirestore,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import ical from "ical-generator";

export function pathFromServiceName(serviceName: string): string {
  return serviceName.toLowerCase().replace(" ", "-");
}

export function timeBlockStringFromStartTime(startTime: Date): string {
  const hour = startTime.getUTCHours() - 4; // EST
  const minutes = startTime.getMinutes();
  const paddedHour = hour === 12 ? "12" : (hour % 12).toString();
  const paddedEndHour =
    (hour + 1) % 12 === 0 ? "12" : ((hour + 1) % 12).toString();
  const startAmPm = hour < 12 ? "am" : "pm";
  const endAmPm = (hour + 1) % 24 < 12 ? "am" : "pm";
  return minutes < 10
    ? `${paddedHour}:0${minutes} ${startAmPm} - ${paddedHour}:5${minutes} ${startAmPm}`
    : `${paddedHour}:${minutes} ${startAmPm} - ${paddedEndHour}:${
        (minutes + 50) % 60 === 0 ? "00" : (minutes + 50) % 60
      } ${endAmPm}`;
}

export async function removeBooking(bookingId: string | null) {
  if (!bookingId) return;
  const db = getFirestore();
  const bookingDoc = await getDoc(doc(db, "bookings", bookingId));
  const bookingData = bookingDoc.data();
  if (!bookingData) {
    console.error("Booking data not found");
    return;
  }
  // Remove the booking from the corresponding slot
  const minTimestamp = new Date(bookingData.startTime.toDate());
  const maxTimestamp = new Date(bookingData.startTime.toDate());
  minTimestamp.setMilliseconds(0);
  minTimestamp.setSeconds(0);
  maxTimestamp.setMilliseconds(999);
  maxTimestamp.setSeconds(59);
  const slotRef = collection(db, "slots");
  const slotsQuery = query(
    slotRef,
    where("startTime", ">=", minTimestamp),
    where("startTime", "<=", maxTimestamp)
  );
  const slotDocs = await getDocs(slotsQuery);
  const slotDoc = slotDocs.docs.find(
    (doc) => doc.data().service === bookingData.service
  );
  if (!slotDoc) {
    console.error("Slot not found");
    return;
  }
  const bookingIds = slotDoc.data().bookingIds;
  const index = bookingIds.indexOf(bookingId);
  if (index > -1) {
    bookingIds.splice(index, 1);
  }
  await updateDoc(slotDoc.ref, { bookingIds });
  // Free up the booking slot
  await deleteDoc(bookingDoc.ref);
  // Reenable other services in this slot
  for (const slot of slotDocs.docs) {
    await updateDoc(slot.ref, { active: true });
  }
}

// export function timeSlotsOnDate(date: Date, serviceName: string, isAdmin: boolean): Date[] {
//     date.setUTCSeconds(0);
//     date.setUTCMilliseconds(0);
//     switch (date.getDay()) {
//         case 1: // Monday
//             return [new Date(date.setUTCHours(15))];
//         case 2: // Tuesday
//             return [
//                 new Date(date.setUTCHours(17)),
//             ];
//         case 3: // Wednesday
//             const wednesdaySlots = [];
//             wednesdaySlots.push(
//                 new Date(date.setUTCHours(14)),
//                 new Date(date.setUTCHours(15)),
//             );
//             if (isAdmin) {
//                 wednesdaySlots.push(new Date(date.setUTCHours(16)));
//             }
//             return wednesdaySlots;
//         case 4: // Thursday
//             if (serviceName === 'Drop-In Class') {
//                 return [new Date(new Date(date.setUTCHours(21)).setUTCMinutes(30))];
//             }
//             return [];
//         case 5: // Friday
//             const fridaySlots = [];
//             if (serviceName === 'Drop-In Class') {
//                 return [new Date(new Date(date.setUTCHours(16)).setUTCMinutes(30))];
//             }
//             if (isAdmin) {
//                 fridaySlots.push(new Date(date.setUTCHours(12)));
//             }
//             fridaySlots.push(
//                 new Date(date.setUTCHours(17)),
//                 new Date(date.setUTCHours(18)),
//             );
//             if (isAdmin) {
//                 fridaySlots.push(new Date(new Date(date.setUTCHours(19)).setUTCMinutes(30)));
//             }
//             return fridaySlots;
//         default:
//             return [];
//     }
// }

export async function timeSlotsOnDate(
  _date: Date,
  serviceName: string,
  isAdmin: boolean
): Promise<Dayjs[]> {
  _date.setUTCSeconds(0);
  _date.setUTCMilliseconds(0);
  const date = dayjs(_date);
  const db = getFirestore();
  const slotsRef = collection(db, "slots");
  // Get all bookings in the same month as selectedMonth
  const slotsQuery = query(
    slotsRef,
    where("startTime", ">=", date.startOf("day").toDate()),
    where("startTime", "<=", date.endOf("day").toDate()),
    where("service", "==", serviceName)
  );
  const slotDocsRef = await getDocs(slotsQuery);
  const slotDocs = slotDocsRef.docs;
  return slotDocs
    .filter((doc) => isAdmin || doc.data().active)
    .map((doc: any) =>
      dayjs((doc.data().startTime as Timestamp).toMillis())
        .set("second", 0)
        .set("millisecond", 0)
    );
}

export const getFutureDatesInMonth = (date: Dayjs): Set<number> => {
  const endDate = date.endOf("month");

  // Create a set of numbers that contains all dates within the month
  const dateSet = new Set<number>();
  for (let i = date.date(); i <= endDate.date(); i++) {
    dateSet.add(i);
  }

  // Remove the days that have already happened
  const pastDays = date.startOf("month").diff(date, "days");
  for (let i = 1; i <= pastDays; i++) {
    dateSet.delete(i);
  }

  return dateSet;
};

export const prices =
  process.env.REACT_APP_ENV === "production"
    ? [
        {
          name: "Drop-In Class",
          slidingScale: true,
          priceId: "price_1NuQ11LhPpLKakdEz7KM3h5p",
        },
        {
          name: "Private Session",
          slidingScale: false,
          priceId: "price_1NuQ19LhPpLKakdEEOT2qqz3",
        },
        {
          name: "Private Session",
          slidingScale: true,
          priceId: "price_1NuQ19LhPpLKakdEGt5AJ3K5",
        },
        {
          name: "Private Session",
          units: 5,
          priceId: "price_1NuQ19LhPpLKakdEGEsWA4zY",
        },
        {
          name: "Private Session",
          units: 10,
          priceId: "price_1NuQ19LhPpLKakdEe17RataY",
        },
        {
          name: "Duet Session",
          slidingScale: false,
          priceId: "price_1NuQ0rLhPpLKakdErabyd7vm",
        },
        {
          name: "Duet Session",
          slidingScale: true,
          priceId: "price_1NuQ0rLhPpLKakdET9DpBL97",
        },
        {
          name: "Duet Session",
          units: 5,
          priceId: "price_1Nuk2OLhPpLKakdER7pyBewa",
        },
        {
          name: "Duet Session",
          units: 10,
          priceId: "price_1Nuk2pLhPpLKakdEuKkfCiyC",
        },
      ]
    : [
        {
          name: "Drop-In Class",
          slidingScale: true,
          priceId: "price_1NPy1HLhPpLKakdEGmNXz9qU",
        },
        {
          name: "Private Session",
          slidingScale: false,
          priceId: "price_1NPTg4LhPpLKakdEcTUBlHIk",
        },
        {
          name: "Private Session",
          slidingScale: true,
          priceId: "price_1NPoMILhPpLKakdEtPLLa5pA",
        },
        {
          name: "Private Session",
          units: 5,
          priceId: "price_1Nu397LhPpLKakdEU4eE7zld",
        },
        {
          name: "Private Session",
          units: 10,
          priceId: "price_1Nu38bLhPpLKakdEWoDCBl8O",
        },
        {
          name: "Duet Session",
          slidingScale: false,
          priceId: "price_1NPTfXLhPpLKakdEHxoFlur9",
        },
        {
          name: "Duet Session",
          slidingScale: true,
          priceId: "price_1NPs7cLhPpLKakdEJoleBshw",
        },
      ];

export async function sendConfirmationEmail(
  bookingId?: string,
  packPurchase?: { packPurchaseId: string; userId: string }
) {
  if (process.env.REACT_APP_ENV !== "production") return;
  if (bookingId) {
    // Get booking doc
    const db = getFirestore();
    const bookingDoc = await getDoc(doc(db, "bookings", bookingId));
    const bookingData = bookingDoc.data();
    if (!bookingData) {
      console.error("Booking data not found");
      return;
    }
    // Create ical attachment
    const startTime = dayjs((bookingData?.startTime as Timestamp).toMillis());
    const endTime = startTime.add(50, "minute");
    const timeSlotString = `${startTime
      .toDate()
      .toDateString()}, ${timeBlockStringFromStartTime(startTime.toDate())}`;
    const cal = ical({
      source: "daisyhodgepilates.com",
      prodId: {
        company: "Daisy Hodge Pilates",
        product: "Daisy Hodge Pilates",
      },
      name: "Daisy Hodge Pilates",
      timezone: "America/New_York",
      events: [
        {
          start: convertToTimeZone(startTime.toDate(), -4),
          end: convertToTimeZone(endTime.toDate(), -4),
          summary: bookingData.service,
          organizer: "Daisy Hodge <daisyhodgepilates@gmail.com>",
          location: {
            title: "The Movement Studio",
            address: "2007 Chapel Hill Rd, Durham, NC 27707",
          },
        },
      ],
    });
    // Send customer confirmation email
    const customerName =
      bookingData.customerName?.split(" ")[0] ?? bookingData.customerEmail;
    const body = `Hello${customerName ? ` ${customerName}` : ""},\n
Thank you for booking a ${
      bookingData.service
    } with Daisy Hodge Pilates. Your booking is confirmed for ${timeSlotString}.
Please note that this booking is nonrefundable if you are within 24 hours of the scheduled start time.\n
Thanks,\nDaisy Hodge Pilates
        `;
    await addDoc(collection(db, "mail"), {
      toUids: [bookingData.customerId as string],
      template: {
        name: "purchaseConfirmation",
        data: {
          body,
          icalData: btoa(cal.toString()),
        },
      },
    });
    // Send admin confirmation email
    const adminBody = `Hi Daisy,\n
A new booking has been made for ${
      bookingData.customerName ?? bookingData.customerEmail
    } on ${timeSlotString}\n
Thanks,\nDaisy Hodge Pilates
        `;
    await addDoc(collection(db, "mail"), {
      to: "daisy.hodge@gmail.com",
      template: {
        name: "purchaseConfirmation",
        data: {
          body: adminBody,
          icalData: btoa(cal.toString()),
        },
      },
    });
  } else if (packPurchase) {
    // Get customer and pack purchase doc
    const db = getFirestore();
    const customerDoc = await getDoc(doc(db, "customers", packPurchase.userId));
    const customerData = customerDoc.data();
    if (!customerData) {
      console.error("Customer data not found");
      return;
    }
    const packPurchaseDoc = await getDoc(
      doc(
        db,
        "customers",
        packPurchase.userId,
        "pack_purchases",
        packPurchase.packPurchaseId
      )
    );
    const packPurchaseData = packPurchaseDoc.data();
    if (!packPurchaseData) {
      console.error("Pack purchase data not found");
      return;
    }
    // Send customer confirmation email
    const customerName = customerData.name?.split(" ")[0] ?? customerData.email;
    const body = `Hello${customerName ? ` ${customerName}` : ""},\n
Thank you for your purchase of a pack of ${packPurchaseData.units} ${
      packPurchaseData.service
    }s with Daisy Hodge Pilates.
Your account has been credited.\n
Thanks,\nDaisy Hodge Pilates
        `;
    await addDoc(collection(db, "mail"), {
      toUids: [packPurchase.userId],
      template: {
        name: "packConfirmation",
        data: {
          body,
        },
      },
    });
  }
}

export function getSchedulerSubtext(serviceName: string) {
  if (serviceName === "Drop-In Class") {
    return (
      <>
        <b>
          <br />
          <br />
          Classes are held:
          <br />
          <br />
          Thursdays at 5:30pm
          <br />
          Fridays at 12:30pm*
          <br />
          <br />
          {"* (virtual only, Zoom link will be emailed)"}
        </b>
        <br />
        <br />
        All classes are sliding scale, from $15 to $25. Classes utilize a
        sliding scale pricing policy to promote community accessibility,
        allowing everyone interested to participate.
        <b>
          {" "}
          The sliding scale principle is based on paying what you can afford,
          not what you think the service should cost, and it should be noted
          that the minimum price is below the market rate.{" "}
        </b>
        By paying the maximum amount, you help subsidize the cost for others
        with fewer resources. If you're unsure about your position on the scale,
        feel free to contact Daisy for guidance.
      </>
    );
  } else if (serviceName === "Private Session") {
    return (
      <>
        Single Private Sessions are $90 for a 50 minute session.
        <br />
        <br />
        <b>*Note:</b> Daisy is currently only accepting new private/duet clients
        on a case-by-case basis.
        <br />
        Please <a href="mailto:daisyhodgepilates@gmail.com">
          contact Daisy
        </a>{" "}
        directly to inquire about availability.
      </>
    );
  } else if (serviceName === "Duet Session") {
    return (
      <>
        Single Duet Sessions are $55 per person for a 50 minute session.
        <br />
        By booking a Duet Session, you are confirming that you have a partner to
        attend with.
        <br />
        <br />
        <b>*Note:</b> Daisy is currently only accepting new private/duet clients
        on a case-by-case basis.
        <br />
        Please <a href="mailto:daisyhodgepilates@gmail.com">
          contact Daisy
        </a>{" "}
        directly to inquire about availability.
      </>
    );
  }
}

export function isPhoneNumber(phoneNumber: string): boolean {
  const pattern = /^(?:\(\d{3}\)\s?|\d{3}-|\d{3})\d{3}-?\d{4}$/;
  return pattern.test(phoneNumber);
}

function convertToTimeZone(date: Date, offset: number) {
  // Convert the offset to milliseconds
  var offsetInMilliseconds = offset * 60 * 60 * 1000;

  // Get the UTC time plus the desired timezone offset
  var localTime = date.getTime();
  var localOffset = date.getTimezoneOffset() * 60000; // getTimezoneOffset returns in minutes
  var utc = localTime + localOffset;
  var newTime = utc + offsetInMilliseconds;

  // Create a new date object with the adjusted time
  return new Date(newTime);
}

export function isUserAdmin(user: User | null): boolean {
  if (!user) return false;
  return ["josh.b.taekman@gmail.com", "daisy.hodge@gmail.com"].includes(
    user.email ?? ""
  );
}
