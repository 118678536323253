import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';

export const firebaseConfig = {
    apiKey: "AIzaSyAFhIeB6PnzRmDkELSMdTmmNDT0vEugK1s",
    authDomain: "daisypilates-1b87f.firebaseapp.com",
    projectId: "daisypilates-1b87f",
    storageBucket: "daisypilates-1b87f.appspot.com",
    messagingSenderId: "678261796846",
    appId: "1:678261796846:web:73ef4db512f723df6128ff",
    measurementId: "G-S4TLQQ2VCE"
};

// Configure FirebaseUI.
export const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};