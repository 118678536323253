import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Paper,
  Button,
} from "@mui/material";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { CheckCircleTwoTone } from "@mui/icons-material";
import { sendConfirmationEmail, timeBlockStringFromStartTime } from "../util";

interface IBooking {
  confirmed: boolean;
  customerId: string;
  startTime: Timestamp;
  service: string;
  // Add other booking properties here
}

const ConfirmationPage: React.FC = () => {
  const { bookingId } = useParams();
  const [booking, setBooking] = useState<(IBooking & { id: string }) | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!bookingId) {
      setError("No booking ID provided");
      return;
    }
    const fetchBooking = async () => {
      const db = getFirestore();
      const bookingRef = doc(db, "bookings", bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (!bookingDoc.exists()) {
        setError("Booking not found");
      } else {
        const bookingData = bookingDoc.data() as IBooking;
        if (bookingData.confirmed) {
          setError("Booking already confirmed");
        } else {
          const minTimestamp = new Date(bookingData.startTime.toDate());
          const maxTimestamp = new Date(bookingData.startTime.toDate());
          minTimestamp.setMilliseconds(0);
          minTimestamp.setSeconds(0);
          maxTimestamp.setMilliseconds(999);
          maxTimestamp.setSeconds(59);
          const slotRef = collection(db, "slots");
          const slotsQuery = query(
            slotRef,
            where("startTime", ">=", minTimestamp),
            where("startTime", "<=", maxTimestamp)
          );
          const slotDocs = await getDocs(slotsQuery);
          const slotDoc = slotDocs.docs.find(
            (doc) => doc.data().service === bookingData.service
          );
          if (!slotDoc) {
            setError("Slot not found");
            return;
          }

          // Disable other services in this slot
          const otherServiceSlots = slotDocs.docs.filter(
            (doc) => doc.data().service !== bookingData.service
          );
          for (const otherSlot of otherServiceSlots) {
            await updateDoc(otherSlot.ref, { active: false });
          }

          // Update the booking to confirmed
          await updateDoc(bookingDoc.ref, { confirmed: true });
          setBooking({ id: bookingDoc.id, ...bookingData, confirmed: true });

          // Send email
          await sendConfirmationEmail(bookingId);
        }
      }
    };
    fetchBooking();
  }, [bookingId]);

  if (error) {
    return (
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          style={{ padding: "2em", marginTop: "2em", textAlign: "center" }}
        >
          <Typography variant="h4" color="error">
            {error}
          </Typography>
          <Link to={"/"}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "2em" }}
            >
              Go Home
            </Button>
          </Link>
        </Paper>
      </Container>
    );
  }

  if (!booking) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  const bookingTime = (booking.startTime as Timestamp).toDate();

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        style={{ padding: "2em", marginTop: "2em", textAlign: "center" }}
      >
        <Typography variant="h5" gutterBottom>
          <b>Your booking</b>
          <br />
          <br />
          {booking.service}
          <br />
          {bookingTime.toLocaleDateString()}
          <br />
          {timeBlockStringFromStartTime(bookingTime)}
          <br />
          <br />
          <b>has been confirmed!</b>
        </Typography>
        {booking.confirmed && (
          <CheckCircleTwoTone color="success" style={{ fontSize: 60 }} />
        )}
        <Typography variant="body1">
          Booking status: {booking.confirmed ? "Confirmed" : "Unconfirmed"}
        </Typography>
        <Link to={"/"}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "2em" }}
          >
            Go Home
          </Button>
        </Link>
      </Paper>
    </Container>
  );
};

export default ConfirmationPage;
