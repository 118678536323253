import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { pathFromServiceName } from "../util";

export default function Services() {
  const services = [
    { id: 1, name: "Drop-In Class", description: "50 minute class" },
    { id: 2, name: "Private Session", description: "50 minute session" },
    {
      id: 3,
      name: "Duet Session",
      description: "50 minute session with a partner",
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        rowSpacing={4}
        paddingX="20px"
        paddingY="40px"
      >
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
            style={{
              fontWeight: "bold",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
            }}
          >
            Services
          </Typography>
        </Grid>

        {services.map((service) => (
          <Grid item xs={12} sm={8} key={service.id}>
            <Card variant="elevation">
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "0px",
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.primary"
                >
                  {service.name}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  {service.description}
                </Typography>
              </CardContent>

              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    (window.location.href = `/scheduler/${pathFromServiceName(
                      service.name
                    )}`)
                  }
                >
                  Book Now
                </Button>

                {service.name !== "Drop-In Class" && (
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    style={{ marginTop: "5px" }}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          (window.location.href = `/packs/${pathFromServiceName(
                            service.name
                          )}/5`)
                        }
                      >
                        Purchase 5 Pack
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          (window.location.href = `/packs/${pathFromServiceName(
                            service.name
                          )}/10`)
                        }
                      >
                        Purchase 10 Pack
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
